<template>
  <v-container fill-height>
    <v-row justify="center" align="center" v-if="!$store.state.currentUser">
      <v-col cols="12" class="text-center">
        <h1>
          Thank you for apply the position at the Department of Astronomy,
          Tsinghua University!
        </h1>
        <h2>Application Deadline:</h2>
        <h3 v-if="deadline.faculty">Faculty: {{ deadline.faculty }} CST</h3>
        <h3 v-if="deadline.postdoc">Postdoc: {{ deadline.postdoc }} CST</h3>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn class="ma-2" color="primary" to="/signup">Signup</v-btn>
        <v-btn class="ma-2" color="primary" to="/login">Login</v-btn>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" v-else>
      <v-col cols="6">
        <v-card v-if="$store.state.currentUser.role == 'none'">
          <v-card-title>Select Application Type</v-card-title>
          <v-card-subtitle>
            Please don't select and contact admin for role promotion if you are
            review expert
          </v-card-subtitle>
          <v-card-text>
            <v-select
              v-model="applicationType"
              :items="applicationTypeSelect"
              item-text="desc"
              item-value="name"
            ></v-select>
            <v-card-actions class="justify-center">
              <v-btn
                type="submit"
                :disabled="!applicationType"
                color="primary"
                @click="handleSelectApplicationType()"
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
        <v-card v-else-if="$store.state.currentUser.role == 'applicant'">
          <v-card-actions class="justify-center">
            <v-btn color="primary" to="/application/info">
              Start Application
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-else-if="$store.state.currentUser.role == 'professor'">
          <v-card-actions class="justify-center">
            <v-btn color="primary" to="/review/applications">
              Applications
            </v-btn>
          </v-card-actions>
          <!-- <v-card-actions class="justify-center">
            <v-btn color="primary" to="/review/schedule">Schedule</v-btn>
          </v-card-actions> -->
        </v-card>
        <v-card
          v-else-if="
            $store.state.currentUser.role == 'admin' ||
            $store.state.currentUser.role == 'owner'
          "
        >
          <v-card-actions class="justify-center">
            <v-btn color="primary" to="/admin/users">Manage Users</v-btn>
          </v-card-actions>
          <v-card-actions class="justify-center">
            <v-btn color="primary" to="/admin/applications">
              Manage Applications
            </v-btn>
          </v-card-actions>
          <!-- <v-card-actions class="justify-center">
            <v-btn color="primary" to="/admin/schedules">
              Manage Schedules
            </v-btn>
          </v-card-actions> -->
        </v-card>
        <v-card v-else>
          <v-card-text> under constructed</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import requests from "@/http";
import usersService from "@/services";

export default {
  data: () => ({
    applicationType: "",
    deadline: {
      faculty: null,
      postdoc: null,
    },
    applicationTypeSelect: [
      { name: "faculty", desc: "Faculty" },
      { name: "postdoc", desc: "Postdoc" },
    ],
  }),
  created() {
    this.$store.commit("updateCurrentUser");
    this.getBasicInfo();
  },
  methods: {
    handleSelectApplicationType() {
      requests
        .post(`/api/users/role`, { application: this.applicationType })
        .then(() => {
          usersService.updateUserInfo().then(() => {
            this.$store.commit("updateCurrentUser");
            this.$router.go();
          });
        });
    },
    getBasicInfo() {
      requests.get(`/api/info`).then((res) => {
        this.deadline.faculty = new Date(
          res.data.deadline.faculty
        ).toLocaleString("en-GB", { hour12: false });
        this.deadline.postdoc = new Date(
          res.data.deadline.postdoc
        ).toLocaleString("en-GB", { hour12: false });
      });
    },
  },
};
</script>
